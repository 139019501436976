var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-wrapper"},[_c('van-form',{ref:"form"},[_c('van-field',{attrs:{"readonly":"","disabled":"","name":"applyName","label":"用户名","placeholder":"请输入姓名","required":""},model:{value:(_vm.form.applyName),callback:function ($$v) {_vm.$set(_vm.form, "applyName", $$v)},expression:"form.applyName"}}),_c('van-field-select',{attrs:{"options":_vm.idCardTypeOptions,"fieldProps":{
        label: '证件类型',
        required: true,
        readonly: true,
        disabled: true,
        placeholder: '请选择证件类型',
      }},model:{value:(_vm.form.idCardType),callback:function ($$v) {_vm.$set(_vm.form, "idCardType", $$v)},expression:"form.idCardType"}}),_c('van-field',{attrs:{"readonly":"","disabled":"","name":"idCardNum","label":"证件号码","placeholder":"请输入证件号码","required":""},model:{value:(_vm.form.idCardNum),callback:function ($$v) {_vm.$set(_vm.form, "idCardNum", $$v)},expression:"form.idCardNum"}}),_c('van-field',{attrs:{"name":"phone","label":"手机号","placeholder":"请输入手机号","required":""},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('van-field',{attrs:{"name":"email","label":"电子邮箱","placeholder":"请输入电子邮箱"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('van-field-area',{attrs:{"name":"area","fieldProps":{
        required: true,
        label: '应聘前所在城市',
        placeholder: '应聘前所在城市',
      }},model:{value:(_vm.form.area),callback:function ($$v) {_vm.$set(_vm.form, "area", $$v)},expression:"form.area"}}),_c('van-field',{attrs:{"name":"bankId","label":"社会保障卡开户银行","placeholder":"请输入社会保障卡开户银行","required":""},model:{value:(_vm.form.bankId),callback:function ($$v) {_vm.$set(_vm.form, "bankId", $$v)},expression:"form.bankId"}}),_c('van-field',{attrs:{"name":"bankAccount","label":"社会保障卡金融账号","placeholder":"请输入社会保障卡金融账号","required":""},model:{value:(_vm.form.bankAccount),callback:function ($$v) {_vm.$set(_vm.form, "bankAccount", $$v)},expression:"form.bankAccount"}}),_c('van-field',{attrs:{"name":"applyCmpy","label":"应聘单位","placeholder":"请输入应聘单位","required":""},model:{value:(_vm.form.applyCmpy),callback:function ($$v) {_vm.$set(_vm.form, "applyCmpy", $$v)},expression:"form.applyCmpy"}}),_c('van-field-calendar',{attrs:{"calendarProps":{
        mode: 'during',
      },"fieldProps":{
        required: true,
        label: '应聘起止日期',
        placeholder: '请输入应聘起止日期',
      },"required":""},model:{value:(_vm.form.applyDate),callback:function ($$v) {_vm.$set(_vm.form, "applyDate", $$v)},expression:"form.applyDate"}}),_c('van-field',{attrs:{"name":"applyAmtYuan","type":"number","label":"交通食宿费金额（元）","placeholder":"请输入交通食宿费金额","required":""},model:{value:(_vm.form.applyAmtYuan),callback:function ($$v) {_vm.$set(_vm.form, "applyAmtYuan", $$v)},expression:"form.applyAmtYuan"}}),_c('div',{staticClass:"tip"},[_c('van-notice-bar',{attrs:{"color":"#1989fa","background":"#ecf9ff","text":"最高学历信息"}})],1),_c('van-field',{attrs:{"name":"college","label":"毕业院校","placeholder":"请输入毕业院校","required":""},model:{value:(_vm.form.college),callback:function ($$v) {_vm.$set(_vm.form, "college", $$v)},expression:"form.college"}}),_c('van-field',{attrs:{"name":"major","label":"所学专业","placeholder":"请输入毕业院校"},model:{value:(_vm.form.major),callback:function ($$v) {_vm.$set(_vm.form, "major", $$v)},expression:"form.major"}}),_c('van-field-select',{attrs:{"options":_vm.educationOptions,"fieldProps":{
        label: '学历',
        placeholder: '请选择学历',
        required: true,
      }},model:{value:(_vm.form.education),callback:function ($$v) {_vm.$set(_vm.form, "education", $$v)},expression:"form.education"}}),_c('van-field',{attrs:{"readonly":"","name":"educationType","label":"学历形式","placeholder":"请输入学历形式","required":""},model:{value:(_vm.form.educationType),callback:function ($$v) {_vm.$set(_vm.form, "educationType", $$v)},expression:"form.educationType"}}),_c('van-field-select',{attrs:{"options":_vm.degreeOptions,"fieldProps":{
        label: '学位',
        placeholder: '请选择学位',
        required: true,
      }},model:{value:(_vm.form.degree),callback:function ($$v) {_vm.$set(_vm.form, "degree", $$v)},expression:"form.degree"}}),_c('van-field-calendar',{attrs:{"name":"graduateDate","label":"毕业时间","placeholder":"请输入","fieldProps":{
        required: true,
        label: '毕业时间',
        placeholder: '请输入毕业时间',
      }},model:{value:(_vm.form.graduateDate),callback:function ($$v) {_vm.$set(_vm.form, "graduateDate", $$v)},expression:"form.graduateDate"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }